<template>
    <div>
        <mt :backType="1">
            <el-form :inline="true" :model="goodsMeta" v-model="goodsMeta">
                <el-form-item label="条件查询">
                    <el-input label="请输入商品名称或条码" v-model="goodsMeta.name"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="queryGoods">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="queryGoodsAddCart">查询并添加到购物车</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="ok" type="primary">确定</el-button>
                </el-form-item>
            </el-form>
        </mt>
        <el-card style="margin: 30px" shadow="always">
            <div slot="header" class="clearfix">
                <template>
                    <span style="margin-right: 24px">候选商品</span>
                </template>
            </div>
            <el-form :inline="true" align="center">

            </el-form>
            <el-table border stripe :data="goods">
                <el-table-column label="序号" type="index" width="80"></el-table-column>
                <el-table-column label="商品名称" prop="name">
                    <template slot-scope="scope">
                        <span>{{scope.row.name}}</span>
                        <i class="el-icon-goods"></i>
                    </template>

                </el-table-column>
                <el-table-column label="条码" prop="bar"></el-table-column>
                <el-table-column label="简称" prop="shortName"></el-table-column>
                <el-table-column label="零售价">
                    <template slot-scope="scope">
                        {{scope.row.price1 | fen2yuan}}
                    </template>
                </el-table-column>
                <el-table-column label="优惠价">
                    <template slot-scope="scope">
                        {{scope.row.price2 | fen2yuan }}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="addToCart(scope.row)">添加到购物车</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pager">
                <el-pagination
                        @size-change="queryGoods"
                        @current-change="queryGoods"
                        :current-page.sync="goodsMeta.page"
                        :page-sizes="[10]"
                        :page-size.sync="goodsMeta.size"
                        layout="sizes, prev, pager, next,total"
                        :total="goodsMeta.total">
                </el-pagination>
            </div>
        </el-card>
        <el-card style="margin: 0 30px 30px 30px" shadow="never">
            <div slot="header" class="clearfix">
                <template>
                    <span style="margin-right: 24px">购物车</span>
                </template>
            </div>
            <el-table border stripe :data="cart">
                <el-table-column label="NO." type="index" width="80"></el-table-column>
                <el-table-column label="商品名称" prop="name">
                </el-table-column>
                <el-table-column label="货号" prop="itemNumber">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.itemNumber"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="参考零售价">
                    <template slot-scope="scope">
                        {{scope.row.price1 | fen2yuan}}
                    </template>
                </el-table-column>
                <el-table-column label="实际售价">
                    <template slot-scope="scope">
                        <el-form v-model="scope.row" :model="scope.row">
                            <el-input-number v-model="scope.row.tPrice" :controls="false" :precision="2"
                                             :min="0" @change="setTprice(scope)"></el-input-number>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column label="购买数量" width="170px">
                    <template slot-scope="scope">
                        <el-input-number  style="width: 140px" v-model="scope.row.count" :controls="false" :precision="0" @change="setCount(scope)">
                        </el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="小计">
                    <template slot-scope="scope">
                        {{_sub(scope.row.count,scope.row.tPrice) | money }}
                    </template>
                </el-table-column>
                <el-table-column label="备注">
                    <template slot-scope="scope">
                        <span v-if="scope.row.tPrice==0">赠送</span>
                        <span v-if="(scope.row.tPrice!=0)&&(scope.row.count>0)">销售</span>
                        <span v-if="(scope.row.tPrice!=0)&&(scope.row.count<0)">退货</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="removeFromCart(scope.$index)">移出购物车</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
    import mt from "@/components/MainTop.vue"

    export default {
        name: "AddGoods",
        components: {mt},
        props: ['form'],
        data() {
            return {
                goodsMeta: {
                    name: "",
                    page: 0,
                    size: 10,
                    total: 0
                },
                goods: [],
                cart: [],
            }
        },
        mounted() {
            this.cart=this.form;
        },
        methods: {
            ok(){
                let obj=JSON.parse(localStorage.getItem("cartGoods")||"{}");
                obj.cart=this.cart;
                localStorage.setItem("cartGoods",JSON.stringify(obj));
                this.goBack();
            },
            queryGoods() {
                this.$http.get("/api/goods/find", {params: this.goodsMeta})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            this.goods = rsp.data.data || [];
                            this.goodsMeta.total = rsp.data.count;
                        }
                    })
            },
            queryGoodsAddCart() {
                if ((!this.goodsMeta.name) || !(this.goodsMeta.name.trim())) {
                    return;
                }
                this.$http.get("/api/goods/find", {params: this.goodsMeta})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            this.goods = rsp.data.data || [];
                            this.goodsMeta.total = rsp.data.count;
                            this._goodsToAdd(this.goods);
                        }
                    })
            },
            addToCart(row) {
                this._goodsToAdd([row]);
            },
            removeFromCart(row) {
                let a = JSON.parse(JSON.stringify(this.cart));
                a.splice(row, 1);
                this.cart = a;
            },
            _goodsToAdd(arr) {
                let ids = this.cart.map(c => c.id);
                let arrToAdd = arr.filter(c => !ids.includes(c.id)).map(c => {
                    c.tPrice = this.fen2yuan(c.price2);
                    c.payType = 1;
                    return c;
                });
                if (arrToAdd.length) {
                    let _a = this.cart.concat(arrToAdd);
                    this.cart=JSON.parse(JSON.stringify(_a));
                }
            },
            _sub(a, b) {
                return a * b;
            },
            setTprice(scope) {
                console.log(scope)
            },
            setCount(scope) {
                console.log(scope)
            },
        }
    }
</script>

<style scoped>

</style>